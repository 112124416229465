import { request } from '@/util/request'

export function feedBack(data) {
  return request({
    url: '/api/FeedBack/FeedBack',
    method: 'post',
    data,
  })
}

export function getFeedBackInfo(params) {
  return request({
    url: '/api/FeedBack/GetFeedBackInfo',
    method: 'get',
    params,
  })
}

export function updateFeedBack(data) {
  return request({
    url: '/api/FeedBack/UpdateFeedBack',
    method: 'post',
    data,
  })
}

export function issues(params) {
  return request({
    url: '/api/FeedBackLook/Issue',
    method: 'get',
    params,
  })
}

export function getFeedBackLookList(params) {
  return request({
    url: '/api/FeedBackLook/GetFeedBackLookList',
    method: 'get',
    params,
  })
}

export function reply(params) {
  return request({
    url: '/api/FeedBackLook/Reply',
    method: 'get',
    params,
  })
}

export function getExcelData(data) {
  return request({
    url: '/api/FeedBackLook/GetExcelData',
    method: 'post',
    data,
  })
}
