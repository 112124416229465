<template>
  <div>
    <div class="weui-cells " style="background-color: white;margin: 14px">

      <div class="weui-cell noline">
        <label style="font-size: 16px">案由：{{ Case_Action }}</label>
      </div>
      <div class="weui-cell ">
        <label style="font-size: 16px">面商人</label>
      </div>
      <div class="weui-cell noline">
        <div class="weui-cell__bd">
          <div class=" weui-cells_checkbox">
            <label class="weui-check__label" for="s1">
              <input type="checkbox"  class="weui-check" name="Counselor" value="单位负责人" id="s1"  v-model="formData.Counselor">
              <i class="weui-icon-checked"></i>单位负责人
            </label>
            <label class="weui-check__label" for="s2">
              <input type="checkbox"  class="weui-check" name="Counselor" value="科室负责人" id="s2" v-model="formData.Counselor">
              <i class="weui-icon-checked"></i>科室负责人
            </label>
            <label class="weui-check__label" for="s3">
              <input type="checkbox"  class="weui-check" name="Counselor" value="一般干部" id="s3" v-model="formData.Counselor">
              <i class="weui-icon-checked"></i>一般干部
            </label>
          </div>
        </div>
      </div>

      <div class="weui-cell ">
        <label style="font-size: 16px">办理协商形式</label>
      </div>
      <div class="weui-cell noline">
        <div class="weui-cell__bd">
          <div class=" weui-cells_checkbox">
            <label class="weui-check__label" for="s4">
              <input type="checkbox"  class="weui-check" name="Counselor" value="座谈会" id="s4"  v-model="formData.Negotiation">
              <i class="weui-icon-checked"></i>座谈会
            </label>
            <label class="weui-check__label" for="s5">
              <input type="checkbox"  class="weui-check" name="Counselor" value="登门走访" id="s5" v-model="formData.Negotiation">
              <i class="weui-icon-checked"></i>登门走访
            </label>
            <label class="weui-check__label" for="s6">
              <input type="checkbox"  class="weui-check" name="Counselor" value="电话" id="s6" v-model="formData.Negotiation">
              <i class="weui-icon-checked"></i>电话
            </label>
            <label class="weui-check__label" for="s7">
              <input type="checkbox"  class="weui-check" name="Counselor" value="未协商" id="s7" v-model="formData.Negotiation">
              <i class="weui-icon-checked"></i>未协商
            </label>
          </div>
        </div>
      </div>

      <div class="weui-cell">
        <label >采纳情况</label>
      </div>
      <div class="weui-cell noline">
        <div class="weui-cell__bd">
          <div class=" weui-cells_checkbox" v-for="(item, index) in AdoptionOptions" :key="index">
            <label class="weui-check__label" >
              <input type="radio" class="weui-check"  :value="item.value" v-model="formData.Adoption">
              <i class="weui-icon-checked"></i>{{item.label}}
            </label>
          </div>
        </div>
      </div>

      <div class="weui-cell">
        <label >对建议答复工作</label>
      </div>
      <div class="weui-cell noline">
        <div class="weui-cell__bd">
          <div class=" weui-cells_checkbox" v-for="(item, index) in Score1Options" :key="index">
            <label class="weui-check__label" >
              <input type="radio" class="weui-check" :value="item.value" v-model="formData.Score1">
              <i class="weui-icon-checked"></i>{{item.label}}
            </label>
          </div>
        </div>
      </div>

      <div class="weui-cell">
        <label >对建议办理措施</label>
      </div>
      <div class="weui-cell noline">
        <div class="weui-cell__bd">
          <div class=" weui-cells_checkbox" v-for="(item, index) in Score2Options" :key="index">
            <label class="weui-check__label" >
              <input type="radio" class="weui-check" :value="item.value" v-model="formData.Score2">
              <i class="weui-icon-checked"></i>{{item.label}}
            </label>
          </div>
        </div>
      </div>

      <div class="weui-cell">
        <label >对办理答复和落实情况的意见</label>
      </div>
      <div class="weui-cell noline">
        <el-input
          v-model="formData.Content"
          type="textarea"
          show-word-limit
          :autosize="{ minRows: 10, maxRows: 10 }"
          :style="{ width: '100%' }"
        />
      </div>

      <div class="weui-cell">
        <label >文件上传</label>
      </div>
      <div class="weui-cell noline">
        <upload-file ref="uploadFile" @fetch-data="getFileList"></upload-file>
      </div>
    </div>
    <div style="margin: 16px;">
      <van-button round block type="info" @click="submit">提交</van-button>
    </div>
    <br>
  </div>
</template>

<script>
  import Base64 from "@/util/Base64";
  import store from "@/store";
  import { Toast } from 'vant';
  import { Notify } from 'vant';
  import { isLock } from "@/api/istManagement";
  import { getPeruseInfo } from '@/api/peruseManagement'
  import { feedBack } from '@/api/scoreManagement'
  import UploadFile from "@/components/UploadFile";
  import {Emas} from "@/assets/js/Emas";
  export default {
    name: "index",
    components: {UploadFile},
    data() {
      return {
        App_ID: 0,
        loading: false,
        Case_Action: '',
        Peruse_ID: 0,
        formData: {
          Counselor: [],
          Negotiation: [],
          Adoption: '采纳',
          Score1: '满意',
          Score2: '满意',
          Content: '',
          fileList: [],
        },
        FileList:[],

        AdoptionOptions: [
          {
            label: '采纳',
            value: '采纳',
          },
          {
            label: '部分采纳',
            value: '部分采纳',
          },
          {
            label: '解释说明',
            value: '解释说明',
          },
          {
            label: '未采纳',
            value: '未采纳',
          },
        ],
        Score1Options: [
          {
            label: '满意',
            value: '满意',
          },
          {
            label: '基本满意',
            value: '基本满意',
          },
          {
            label: '不满意',
            value: '不满意',
          },
        ],
        Score2Options: [
          {
            label: '满意',
            value: '满意',
          },
          {
            label: '基本满意',
            value: '基本满意',
          },
          {
            label: '不满意',
            value: '不满意',
          },
        ],

      }
    },
    created() {
      this.App_ID = Base64.decode(this.$route.query.App_ID)
      this.IST_Node_ID = Base64.decode(this.$route.query.IST_Node_ID)
      this.Case_Action = this.$route.query.Case_Action
      this.IsLock()
      this.fetchData()
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/score", "满意度", "zzdzx.yy.gov.cn/score")
    },
    methods: {

      async fetchData() {
        const { data } = await  getPeruseInfo({App_ID: this.App_ID, Type: 1})
        this.Peruse_ID = data[0].peruse_ID
      },

      async submit() {


        if(this.checkScore()) {
          let obj = {
            App_ID: this.App_ID,
            Counselor: this.formData.Counselor.join(','),
            Negotiation: this.formData.Negotiation.join(','),
            Adoption: this.formData.Adoption,
            Score1: this.formData.Score1,
            Score2: this.formData.Score2,
            Content: this.formData.Content,
            FileList: this.handleFile(this.FileList),
            Operator: store.getters['user/userInfo'].user_ID,
            Peruse_ID: this.Peruse_ID,
            Type: 2,
          }
          Toast.loading({
            duration: 0, // 持续展示 toast
            message: '正在回复...',
            forbidClick: true,
          });
          const { msg } = await feedBack(obj)
          Toast.success(msg);
          Toast.clear()
          await this.$router.push('/index')
        }
      },

      async IsLock() {
        const { success, msg } = await isLock({ IST_Node_ID: this.IST_Node_ID })
        if (success) {
          Toast.success(msg);
          await this.$router.push('/index')
        }
      },

      getFileList(obj) {
        this.FileList = obj
      },

      //处理文件
      handleFile(fileList) {
        console.log(fileList)
        if (fileList.length > 0) {
          let arr = []
          fileList.forEach(function (item) {
            let a = ''
            a = item.name + '|' + item.url
            arr.push(a)
          })
          return arr
        } else {
          return null
        }
      },


      checkScore() {
        if(this.formData.Counselor.length <= 0 ) {
          Notify({ type: 'danger', message: '请选择面商人' });
          return false
        }
        if(this.formData.Negotiation.length <= 0 ) {
          Notify({ type: 'danger', message: '请选择办理协商形式' });
          return false
        }
        if(this.formData.Content === '' ) {
          Notify({ type: 'danger', message: '请输入对办理答复和落实情况的意见' });
          return false
        }
        return true
      }
    }
  }
</script>

<style scoped>

  @import "../../assets/css/weui.min.css";

  .weui-cell {
    font-size: 16px;
  }

  .noline:before{
    border-top:0
  }
  .weui-check__label {
    float: left;
  }
</style>