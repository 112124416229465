<template>
  <div>
    <el-upload
      ref="File"
      :file-list="FileList"
      :action="action"
      :on-remove="handleRemove"
      :on-progress="handleProgress"
      :http-request="uploadFile"
      :on-preview="handlePreview"
      multiple
      :accept="fileType"
    >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-upload"
      >
        点击上传
      </el-button>
    </el-upload>
  </div>
</template>

<script>
  import store from "@/store";
  import download from "@/util/downloadFiles";
  import {fileType} from '@/config/setting.config'
  import {uploadFile} from "@/api/uploadManagement";
  import {Emas} from "@/assets/js/Emas";
  export default {
    name: "UploadFile",
    props:{
      FileList: {type:Array, default: () => []},
    },
    data() {
      return {
        action: 'api/Upload/PostFile',
        fileType: '*',
        //FileList:[],
      }
    },
    created() {
      this.fileType = fileType
    },
    mounted() {
      let userInfo = store.getters["user/userInfo"]
      let User_ID = userInfo.user_ID
      let Staff_Name = userInfo.staff_Name
      Emas(Staff_Name, User_ID,"/UploadFile", "文件列表", "zzdzx.yy.gov.cn/#/UploadFile")
    },
    methods:{
      //上传文件
      async uploadFile(params) {
        const formData = new FormData()
        formData.append('file', params.file)
        const { data } = await uploadFile(formData)
        this.FileList.push(data)
        this.$emit('fetch-data', this.FileList)
      },
      //文件上传时
      handleProgress() {
        this.uploadFlag = false
      },
      //文件上传移除操作
      handleRemove(file, fileList) {
        this.FileList = []
        this.FileList = fileList
        console.log(this.FileList)
      },
      //
      handlePreview(file) {
        download.downloadFiles(
            file.url,
            file.name.substring(0, file.name.length - 20),
            file.name.split('.')[1]
        )
      },
      //处理文件
      handleFile(fileList) {
        console.log(fileList)
        if (fileList.length > 0) {
          let arr = []
          fileList.forEach(function (item) {
            let a = ''
            a = item.name + '|' + item.url
            arr.push(a)
          })
          return arr
        } else {
          return null
        }
      },
    }
  }
</script>

<style scoped>

</style>